import Phaser from 'phaser'

import Game from "./scenes/Game";

const config = {
    type: Phaser.AUTO,
    parent: 'game',
    backgroundColor: '#fff',
    dom: {
        createContainer: true
    },
    physics: {
        default: 'arcade',
        // arcade: { debug: true }
    },
    scale: {
        width: 500,
        height: 800,
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH
    },
    scene: [Game]
}

export default new Phaser.Game(config)
