import Phaser from 'phaser';
import strawberry from '/public/assets/strawberry.png'
import pig from '/public/assets/pig.png'
import spiked from '/public/assets/spiked-ball.png'
import skupljac from '/public/assets/skupljac.png'
import burger from '/public/assets/burger_2.png'

export default class Game extends Phaser.Scene {

    playerSpeed = 10;
    player;
    cursors;
    moveTo = null;
    bombs;
    stars;
    lastGamePoints = null;
    points = 0;
    gameRunning = false;
    timeLeft = 60;

    constructor() {
        super('GameScene');
    }

    preload() {
        this.load.image('bomb', spiked);
        this.load.image('star', strawberry);
        this.load.image('pig', pig);
        this.load.image('skupljac', skupljac);
        this.load.image('burger', burger);
    }

    create() {

        this.timeText = this.add.text(355, 16, 'Time:60', {fontSize: '32px', color: '#000'});
        this.socreText = this.add.text(16, 16, 'Score:', {fontSize: '32px', color: '#000'});
        this.pointWonText = this.add.text(250, 450, '', {fontSize: '24px', color: '#000'}).setOrigin(.5);

        this.startText = this.add.text(250, 400, 'Tap to play', {fontSize: '42px', color: '#000'}).setOrigin(.5);

        this.startText.setInteractive();


        this.r1 = this.add.rectangle(200, 1000, 800, 1, 0x6666ff);

        this.physics.add.existing(this.r1, true);
        this.socreText.visible = false;
        this.timeText.visible = false;

        this.startText.on('pointerdown', (e) => {
            if (this.gameRunning) {
                return;
            }

            this.gameRunning = true;
            this.startGame();
        });

        this.gameRunning = true;
        this.startGame();
    }

    update(time, delta) {
        super.update(time, delta);

        if (!this.gameRunning) {
            return;
        }

        this.timeText.setText('Time:' + this.timeLeft);
        this.socreText.setText('Score:' + this.points);

        if (!this.player) return;
        const activePointer = this.input.activePointer;
        const playerVelocity = this.player.body.velocity.x;
        const playerX = this.player.body.position.x;
        const moveTo = activePointer.x - 40;

        if (!activePointer.isDown) {
            if (playerVelocity !== 0) {
                this.player.setVelocity(0, 0);
            }
            return;
        }

        const diff = moveTo - playerX;

        if (diff > 10) {
            this.player.setVelocity(this.playerSpeed * diff, 0);
        } else if (diff < -10) {
            this.player.setVelocity(this.playerSpeed * diff, 0);
        } else {
            this.player.setVelocity(0, 0);
        }
    }

    endGame() {
        if (!this.gameRunning) return;

        this.pointWonText.visible = true;
        this.pointWonText.setText('Vas rezultat je ' + this.points);
        this.socreText.visible = false;
        this.lastGamePoints = this.points;
        this.stars.clear(true);
        this.points = 0;
        this.timeLeft = 60;
        this.gameRunning = false;
        this.startText.visible = true;
        this.player.visible = false;
        this.timeText.visible = false;
        this.time.removeEvent(this.mainEvent);
    }

    startGame() {


        this.bombs = this.physics.add.group({
            key: 'bomb',
            frameQuantity: 60,
            maxSize: -1,
            active: false,
            visible: false,
            enable: false,
            collideWorldBounds: false,
            bounceX: 0,
            bounceY: 0,
            dragX: 0,
            dragY: 0
        });

        this.stars = this.physics.add.group({
            key: 'burger',
            frameQuantity: 60,
            maxSize: -1,
            active: false,
            visible: false,
            enable: false,
            collideWorldBounds: false,
            bounceX: 0,
            bounceY: 0,
            dragX: 0,
            dragY: 0
        });


        this.player = this.physics.add.sprite(250, 680, 'skupljac').setOrigin(.5);
        this.player.setCollideWorldBounds(true);
        this.cursors = this.input.keyboard.createCursorKeys();


        this.physics.add.overlap(this.r1, this.stars, (_r1, _star) => {
            _star.disableBody(true, true);
        });

        this.physics.add.overlap(this.player, this.bombs, (_player, _bomb) => {

            if (this.points >= 10) this.points -= 10;

            _bomb.disableBody(true, true);
        });

        this.physics.add.overlap(this.player, this.stars, (_player, _star) => {
            this.points += 10;

            _star.disableBody(true, true);
        });


        this.mainEvent = this.time.addEvent({
            loop: true, delay: 1000, callback: () => {

                if (this.timeLeft <= 1) {
                    this.endGame();
                    return;
                }


                if (!this.gameRunning) return;


                let starCount;
                let vel;
                if (this.timeLeft > 40) {
                    starCount = 2;
                    vel = Phaser.Math.Between(150, 250);
                } else if (this.timeLeft > 20) {
                    starCount = 3;
                    vel = Phaser.Math.Between(250, 350);
                } else {
                    starCount = 4;
                    vel = Phaser.Math.Between(350, 450);
                }

                for (let i = 0; i < starCount; i++) {
                    const star = this.stars.get();

                    if (!star) continue;
                    star.enableBody(true, Phaser.Math.Between(40, 460), Phaser.Math.Between(-200, -40), true, true).setVelocity(0, vel);
                }

                this.timeLeft--;
            }
        });

        this.socreText.visible = true;
        this.startText.visible = false;
        this.player.visible = true;
        this.timeText.visible = true;
        this.pointWonText.visible = false;

    }
}
